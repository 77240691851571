import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


//import {GET_VEHICLETYPE, GET_ADDCAR }  from '../../actions'; 


const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
  
const initialState = {
			ad_name:'',
			ad_description:'',
			ad_short_desc:'',
			ad_link:'',			
			ad_button_text:'',			
			
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedsizevalue: '',
            selectedsizeOption:{ value: '350x350', label: '350x350'},
			user_image: [],
			vehicletype:[],
			user_image_name: [],
			user_image_preview: [],
			image: [],
			isHidden: false,
			statusmessage:'',
			selectedVehicle:'',
			vehiclevalue:'',
			Loading:false,
			fileName: [],
			thumbDefault: null,
			priority: "",			
			
			
			admin_id: localStorage.getItem('admin_id'),
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No'
		};
		
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.handleReset = this.handleReset.bind(this);
	   this.handlesizeChange = this.handlesizeChange.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
    }


	handleReset() {
         this.setState(initialState);
		 this.fileObj = [];
		 this.fileArray = [];
		 this.imageArray = [];
    }

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handlesizeChange = selectedsizeOption => {
        this.setState({selectedsizeOption})
        this.setState({  selectedsizevalue : selectedsizeOption.value});
		
    };
   handleInputChange(event) {

      const {name, value} = event.target;    
      this.setState({
          [name]: value
        });
    }
	onEditorChange( evt ) {
		var callfunc = this;
		setTimeout(function () {
		this.setState( {
			template_content: evt
		} );
		}.bind(this),1000);
 
	}
	componentDidMount() {
		document.title = PageTitle('Advertisement Add');
    }

   handleChangeVehicle = selectedVehicle => {
      this.setState({ selectedVehicle});
      this.setState({ vehiclevalue : selectedVehicle.value });
      if(selectedVehicle.value ==''){
        $('.errorvehicletype').html('<span class="errorspan">Please fill the field</span>');
      }

      let vehicletruck = selectedVehicle.label;
      let lowercasetext = vehicletruck.toLowerCase();
      if(lowercasetext == 'truck'){
      	this.setState({enableweight: true})
      }else{
      	this.setState({enableweight: false})
      }
   }


onThumbChanged = (e) => {
    this.setState({
      thumbDefault: e.currentTarget.value
      });
  }
  
uploadMultipleFiles = (e) =>{

		this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ user_image: this.imageArray });			
	
    }
	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				
				var imgSize = '';
				if(formPayload.selectedsizevalue === ''){
				imgSize =formPayload.selectedsizeOption.value;
				}else{
				imgSize = formPayload.selectedsizevalue;
				}
                
                var galleryimg = 'No';
				if(Object.keys(formPayload.selectedimages).length >0){
                   galleryimg = 'Yes';
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					ad_name: formPayload.ad_name,
					ad_description:formPayload.ad_description,
					ad_short_desc:formPayload.ad_short_desc,
					ad_link:formPayload.ad_link,				
					ad_button_text:formPayload.ad_button_text,					
					ad_image        : formPayload.user_image,
					ad_status      :status,					
					uploadfromgallery : galleryimg					
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
                
                var filesdata;
                if(galleryimg == 'No'){
                    filesdata = this.state.user_image;	
                }else{
                	 filesdata = this.state.selectedimages;	
                }
				
				
				for (var i in filesdata) {
				 formData.append('ad_image[]',filesdata[i])
				}	
							
				console.log(formData,'-formData')
				
				axios.post(apiUrl+"advertisement/add",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							ad_name: '',
							ad_description:'',
							ad_short_desc:'',
							ad_link:'',			
							ad_button_text:'',	
							ad_image : '',
							ad_status :'',							
							uploadfromgallery: ''	
						   });
						   setTimeout(
						   function() {
					
							   $('.success_message').html('');
									this.props.navigate('/advertisement');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {ad_name,vehicle_color,ad_description,ad_link,selectedVehicle,user_image} = this.state;
		let errors = 0;
	const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb
		if (!ad_name) {
			errors++;
			$('.errorad_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_name){
			$('.errorad_name').html('');
		}


		if (!ad_description) {
			errors++;
			$('.errorad_description').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_description){
			$('.errorad_description').html('');
		}
	
		if (!ad_link) {
			errors++;
			$('.errorad_link').html('<span class="errorspan">Please fill the field</span>');
		}else if(ad_link){
			$('.errorad_link').html('');
		}
		

		
		if(user_image.length == 0){
			errors++;
			$('.erroradimage').html('<span class="errorspan">Please upload advertisement images</span>');
		}
		else if(user_image) {	 
			
	             for (let i = 0; i < user_image.length; i++) 
				 {

					const file = user_image[i];
					const filename = file.name;

					
					if (!allowedTypes.includes(file.type)) {
						errors++;
					
					$('.erroradimage').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

					continue;
					}
	            }
	       }


		if(errors>0) { console.log('validation error')
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else { console.log('validation error no')
		return true;
		}

    }

    
	removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		// var thumbDefault = this.state.thumbDefault;
		// if(indexvalue <= thumbDefault){
		// 	let thum = thumbDefault-1;
		// 	 if(thum < 0 ){
		// 	   this.setState({thumbDefault : 0})
		// 	 }else{
		// 		this.setState({thumbDefault : thum});
		// 	}    
		// }
	}
	
	
	 getImagesFrontUpload(){
		 /*====Thumb image while choosing from front end===*/
		  if(Object.keys(this.fileArray).length > 0) {
			//var thumbDefaultArr = this.state.thumbDefault;
		 
			 const imgThumblist = this.fileArray.map((url, index) => {
						//var indicheckFlag = false;
												
						//if(Object.keys(thumbDefaultArr).length > 0){
							// if(thumbDefaultArr != null && thumbDefaultArr == index){
							// 	indicheckFlag = true;
							// }
						//}
						  return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={url}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}
	 }

   //list selected image  from gallery
	 getafteruploaded(){
	 	var imgArray = this.state.selectedimages;
	 	if(Object.keys(imgArray).length > 0) {
		 	//var thumbDefaultArr = this.state.thumbDefault;
			 const imgThumblist = imgArray.map((images, index) => {
			 	console.log(images,'images')
			 			//var indicheckFlag = false;
		 				// if(thumbDefaultArr != null && thumbDefaultArr == index){
						// 	indicheckFlag = true;
						// } 
						  return (
							<li className="thumb" key={index}  >
								{/* <input type="radio" name="sel_img" id="sel_img"  value={index} onClick={this.onThumbChanged} checked={indicheckFlag} /> */}
								<span><img src={mediaUrl+'/advertisement/'+images}  alt="..." /></span>
								<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
							</li>
							 );
			 });	
				return imgThumblist;					
			/*=======*/
		}

	 }
  //check gallery images
   Checkgallery = (indexs,value) =>{
      var imageArr = [...this.state.selectedimages];
      const index = imageArr.findIndex(images => images === indexs);
      if(index > -1) {
     	 imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
      } else {
      imageArr.push(value);
      }
      this.setState({selectedimages: imageArr});
  }


 getGalleryimages() {
    var imageArr = this.state.imagelist;
    if(imageArr!== undefined && imageArr!== null){
      if(Object.keys(imageArr).length > 0) {
         const imageDetails = imageArr.map((image, Index) => {
       		var checked = false;
            return (
                        <div className="asp-col" key={Index}>   
                        <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
                        <label><img src={image['url']} alt="" className="" /></label>
                        </div>
                     );
    
     });
      return imageDetails;
     }
    } else {
      return (<div className="">No images found</div>);
    }
  }

   galleryClose = () => {

   		if(Object.keys(this.state.selectedimages).length > 0){
   			 this.setState({galleryimg: 'Yes'});
   		}
   	    this.setState(prevState => ({
			enableuploadgalley: !prevState.enableuploadgalley
		}));
		$('.cargallery-popup').removeClass('active');
   }


  render() {

  	
	let imagesrc = '';
	
  	const {selectedOption,selectedVehicle, user_image_preview} = this.state;

	

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="advertisement" />  

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="form-wrapper">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/advertisement">Back</a>
			</div>
			<div className="title">
				<h4>Add Advertisement</h4>
			</div>
			<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
				<div className="form-row">
					<div className="form-left">
						<div className="form-group">
							<label>Name:</label>
							<input type="text" name="ad_name" onChange={this.handleInputChange} className="form-control" value={this.state.ad_name} />
							<div className="errorad_name"></div>
						</div>
						<div className="form-group">					
							<label>Description:</label>
								{/* <input type="text" name="ad_description" onChange={this.handleInputChange} className="form-control" value={this.state.ad_description} /> */}
								{/* <MyEditor initialData={this.state.ad_description} onEditorChange={this.onEditorChange}> </MyEditor> */}
								<CKEditor
									editor={ ClassicEditor }
									data={this.state.ad_description}
									onReady={ editor => {
										// You can store the "editor" and use when it is needed.
										console.log( 'Editor is ready to use!', editor );
										editor.ui.view.editable.element.style.minHeight = "300px";
									} }
									name="ad_description"
									// onChange={this.handleInputChange}
									onChange={ ( event, editor ) => {
										const data = editor.getData();
										this.setState({ad_description: data});
										editor.ui.view.editable.element.style.minHeight = "300px";
										
									} }
								/>
							<div className="errorad_description"></div>
						</div>
						<div className="form-group">
							<label>Button Text:</label>
								<input type="text" name="ad_button_text" onChange={this.handleInputChange} className="form-control" value={this.state.ad_button_text} />
							<div className="errorad_button_text"></div>
						</div>
						
						
					</div>				
					<div className="form-right">				
						<div className="form-group">
							<label>Link Url: </label>
							<input type="text" name="ad_link" onChange={this.handleInputChange} className="form-group" value={this.state.ad_link} />
							<div className="errorad_link"></div>
						</div>
						<div className="form-group">
							<label>Short Description:</label>
								<input type="text" name="ad_short_desc" onChange={this.handleInputChange} className="form-control" value={this.state.ad_short_desc} autoComplete="off" />
							<div className="errorad_short_desc"></div>
						</div>
						
						<div className="form-group">
							<label>Image:</label>
							<div className="choose-file">
								{this.state.isHidden && <div className="image_success"></div>}
								<span className="profile_btn">
								<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple />
								</span>
							</div>
							<div className="form-group">
								<ul className="carimagelisting">
									{(this.state.galleryimg === 'No')?this.getImagesFrontUpload():this.getafteruploaded()}
								</ul>
							</div>
							<div className="erroradimage"></div> 
						</div>
						<div className="form-group">					
							<label>Status:</label>
							<Select 
							value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							options={lang.common.status_option} 
							onChange={this.handleChange}
							/>
						</div>
					</div>	
				</div>		

				<div className="btn-group export">	
					<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&	<span className="load-data"></span> }Submit </button>
				</div>
			</form>
			</div>
		</div>	
	</div>
    </div>
    );
  }
}


export default (withRouter(Add));
